<template>
  <div class="container">
    <v-breadcrumbs class="d-print-none">
      <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
        >Home</v-breadcrumbs-item
      >
      <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
      <v-breadcrumbs-item disabled> Print Package Slip </v-breadcrumbs-item>
    </v-breadcrumbs>

    <div class="d-print-none pb-5">
      <div class="d-flex justify-space-between">
        <v-btn @click="goBack">
          Back
        </v-btn>
        <div class="d-flex flex-column justify-center">
          <v-btn class="mx-2" @click="print()">{{
            $vuetify.lang.t("$vuetify.printSetting.print")
          }}</v-btn>
          <v-switch hide-details dense class="my-auto mt-2" v-model="condensePrint" :value="true" label="Condensed"/>
        </div>
        <v-btn
          v-if="worksheet"
          class="mx-2"
          :to="{ name: 'worksheetPackingCheck', params: { id: worksheet.id } }"
          >{{$vuetify.lang.t("$vuetify.packaging.checkPackaging")}}</v-btn
        >
      </div>
      <v-divider />
    </div>

    <table v-if="loaded" style="width: 100%">
      <thead>
        <tr>
          <th>
            <company-letter-head class="pb-5" />
            <v-divider />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div
              class="mx-5 d-flex flex-column flex-sm-row print-flex-row flex-space-between"
            >
              <div class="mr-auto">
                <table class="cell-spacing">
                  <tr>
                    <td class="font-weight-bold">
                      {{ $vuetify.lang.t("$vuetify.packaging.commodity") }}
                    </td>
                    <td>{{ worksheet.productName }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $vuetify.lang.t("$vuetify.packaging.customer") }}
                    </td>
                    <td>{{ worksheet.customerName }}</td>
                  </tr>
                  <tr v-if="worksheet.documentNumber">
                    <td class="font-weight-bold">
                      {{ $vuetify.lang.t("$vuetify.packaging.weightSlip") }}
                    </td>
                    <td>
                      <div
                        class="d-none d-print-block"
                        v-if="worksheet.documentNumber"
                      >
                        {{ worksheet.documentNumber }}
                      </div>
                      <v-text-field
                        class="d-print-none"
                        outlined
                        dense
                        hide-details
                        v-model="weightSlipNo"
                        :label="
                          $vuetify.lang.t('$vuetify.packaging.weightSlip')
                        "
                        @blur="updateWorksheet(worksheet)"
                      />
                    </td>
                  </tr>
                </table>
              </div>
              <div class="ml-0 ml-md-auto">
                <table class="cell-spacing">
                  <tr>
                    <td class="font-weight-bold">
                      {{ $vuetify.lang.t("$vuetify.base.date") }}
                    </td>
                    <td>{{ worksheet.createTime | formatDateYear }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $vuetify.lang.t("$vuetify.packaging.truckNo") }}
                    </td>
                    <td>
                      <div
                        class="d-print-block d-flex justify-space-between"
                        v-if="!chooseVehicle"
                      >
                        {{
                          worksheet.vehiclePlateNumber
                            ? worksheet.vehiclePlateNumber
                            : "N/A"
                        }}
                        <v-btn
                          class="ml-4 d-print-none"
                          @click="chooseVehicle = true"
                          icon
                          small
                          ><v-icon>mdi-pencil</v-icon></v-btn
                        >
                      </div>
                      <vehicle-select
                        v-if="chooseVehicle"
                        @selected="
                          updateWorksheet(worksheet);
                          chooseVehicle = false;
                        "
                        :icon="null"
                        class="d-print-none"
                        v-model="vehicle"
                      />
                    </td>
                  </tr>
                  <tr
                    :class="worksheet.certificateNumber ? '' : 'd-print-none'"
                  >
                    <td class="font-weight-bold">
                      {{ $vuetify.lang.t("$vuetify.packaging.certificate") }}
                    </td>
                    <td>
                      <div
                        class="d-none d-print-block"
                        v-if="certificateNumber"
                      >
                        {{ worksheet.certificateNumber }}
                      </div>
                      <v-text-field
                        class="d-print-none"
                        outlined
                        dense
                        hide-details
                        v-model="certificateNumber"
                        :label="
                          $vuetify.lang.t('$vuetify.packaging.certificate')
                        "
                        @blur="updateWorksheet(worksheet)"
                      />
                    </td>
                  </tr>
                  <tr :class="worksheet.poNumber ? '' : 'd-print-none'">
                    <td class="font-weight-bold">
                      {{ $vuetify.lang.t("$vuetify.packaging.poNumber") }}
                    </td>
                    <td>
                      <div class="d-none d-print-block" v-if="poNumber">
                        {{ worksheet.poNumber }}
                      </div>
                      <v-text-field
                        class="d-print-none"
                        outlined
                        dense
                        hide-details
                        v-model="poNumber"
                        :label="$vuetify.lang.t('$vuetify.packaging.poNumber')"
                        @blur="updateWorksheet(worksheet)"
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="mx-8">
              <div
                :class="`font-weight-thin text-caption ${
                  worksheet.customerMemo ? 'd-print-block' : 'd-print-none'
                }`"
              >
                {{ $vuetify.lang.t("$vuetify.base.memo") }}
              </div>
              <div
                :class="`mt-2 pa-2 d-none ${
                  worksheet.customerMemo ? 'd-print-block' : 'd-print-none'
                }`"
                style="border: 1px solid #ccc; min-height: 80px"
              >
                {{ worksheet.customerMemo }}
              </div>
              <v-textarea
                hide-details
                class="d-print-none"
                v-model="customerMemo"
                outlined
                rows="2"
                @blur="updateWorksheet(worksheet)"
              />
            </div>

            <div class="mx-6 mt-8">
              <table :class="`table ${condensePrint ? 'table-sm':''}`">
                <thead class="font-weight-bold">
                  <tr>
                    <td></td>
                    <td>Lot</td>
                    <td>{{ $vuetify.lang.t("$vuetify.base.weight") }}</td>
                    <td>{{ $vuetify.lang.t("$vuetify.base.notes") }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(entry, index) in worksheet.entries"
                    :key="entry.id"
                    align-content="center"
                    :set="
                      (lotNumber = getEntryLotNumber(
                        entry.inventoryId,
                        entry.inventoryUnitId
                      ))
                    "
                  >
                    <td>{{ index + 1 }}.</td>
                    <td>
                      {{ lotNumber }}
                    </td>
                    <td>
                      {{ entry.netWeight + entry.netAdjustmentWeight | formatNumber }}
                      {{ productInfo ? productInfo.measureWeight : "" }}
                    </td>
                    <td>{{ entry.notes }}</td>
                  </tr>
                  <tr class="font-weight-bold">
                    <td>{{ $vuetify.lang.t("$vuetify.base.total") }}</td>
                    <td v-if="worksheet.entries">
                      {{ worksheet.entries.length }}
                      {{ productInfo ? productInfo.measureUnit : "" }}
                    </td>
                    <td>
                      {{ totalWeight(worksheet.entries) | formatNumber }}
                      {{ productInfo ? productInfo.measureWeight : "" }}
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <div class="dont-break">
              <v-divider />
              <div
                v-if="signatures && signatures.length > 0"
                class="d-flex justify-space-between flex-column flex-sm-row mx-2 mt-5 print-flex-row"
              >
                <div
                  style="max-width: 300px"
                  class="print-img flex-grow-1 mx-2 mx-md-5 mb-10 mb-md-0"
                  v-for="signature in signatures"
                  :key="signature.id"
                >
                  <signature
                    v-model="signature.signature"
                    :signature="signature"
                    :signatureHeader="signature.signatureHeader"
                    :signatureFooter="signature.signatureFooter"
                  />
                  <!-- <div
                    class="d-flex justify-space-between text-decoration-underline text-caption mt-2"
                    v-if="signature.updatedDate"
                  >
                    <span>{{ $vuetify.lang.t("$vuetify.base.date") }}</span>
                    <span>{{ signature.updatedDate | formatDateYear }}</span>
                  </div> -->
                </div>
              </div>
            </div>

            <div class="pt-8" v-if="images && images.length > 0">
              <v-divider />
              <v-subheader class="justify-center">{{
                $vuetify.lang.t("$vuetify.packaging.images")
              }}</v-subheader>
              <v-row class="mx-2">
                <v-col
                  :class="`dont-break ${img.selected ? '' : 'd-print-none'}`"
                  cols="6"
                  v-for="(img, index) in images"
                  :key="index"
                >
                  <v-checkbox
                    class="d-print-none"
                    hide-details
                    v-model="img.selected"
                    :label="$vuetify.lang.t('$vuetify.printSetting.print')"
                  />
                  <div style="border: 1px solid">
                    <v-img
                      class="print-img"
                      width="80%"
                      contain
                      aspect-ratio="1.7"
                      :src="img.src"
                    />
                  </div>
                </v-col>
              </v-row>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div style="height: 20px" />
            <v-divider />
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <div class="d-flex justify-space-between">
              <span v-if="isPanya">บันทึก : จัดเก็บในแฟ้มเรียงตามลำดับเลขที่, ระยะเวลาจัดเก็บ 2 ปี,  วิธีการทำลายย่อย, ผู้รับผิดชอบ MK</span>
              <span v-if="isPanya">FM-MK-01-08 Rev.01 Eff. Date : 01/12/22</span>

              <span v-if="!isPanya">แบบฟอร์มใบแจ้งน้ำหนักตะกั่ว F-09-11 ฉบับที่ 03 วันที่บังคับใช้ 14 มิ.ย. 65</span>
            </div>
            <div class="d-flex justify-end font-weight-light text-caption">
              {{
                $vuetify.lang.t(
                  "$vuetify.packaging.printOn",
                  $options.filters.formatDateYear(printDate)
                )
              }}
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { getProcessWorksheet } from "@/services/processWorksheet";
import { mapActions } from "vuex";
import { parseDomain } from "parse-domain";
import moment from "moment";
const CompanyLetterHead = () =>
  import("@/components/common/CompanyLetterHead.vue");
const VehicleSelect = () =>
  import("@/components/companyVehicle/VehicleSelect.vue");
const Signature = () => import("@/components/common/Signature.vue");

export default {
  data() {
    return {
      companyName: process.env.VUE_APP_COMPANY_NAME,
      id: "",
      worksheet: {},
      customerMemo: null,
      weightSlipNo: null,
      loaded: false,
      productInfo: null,
      domainUrl: null,
      printDate: moment(),
      condensePrint: false,
      signatures: [],
      truckNumber: null,
      vehicle: null,
      chooseVehicle: false,
      certificateNumber: null,
      poNumber: null,
      inventories: [],
      images: [],
    };
  },
  components: { Signature, VehicleSelect, CompanyLetterHead },
  computed: {
    isPanya(){
      return this.companyName.startsWith("Panyaraksa")
    }
  },
  methods: {
    ...mapActions("messages", [
        "addMessage",
        "addErrorMessage",
        "addSuccessMessage"
      ]),
    ...mapActions("navigation", ["hideDrawer"]),
    ...mapActions("categoryStore", ["fetchCategory"]),
    ...mapActions("processWorksheet", ["updateProcessWorksheet"]),

    goBack() {
      this.$router.go(-1);
    },
    async print() {
      await this.hideDrawer();
      window.print();
    },
    setInstance() {
      this.setDependencies().then(() => {
        getProcessWorksheet(this.id)
          .then((response) => {
            this.worksheet = response.data;
            this.customerMemo = this.worksheet.customerMemo;
            this.weightSlipNo = this.worksheet.documentNumber;
            this.certificateNumber = this.worksheet.certificateNumber;
            this.poNumber = this.worksheet.poNumber;
            if (this.worksheet.vehicleId) {
              this.vehicle = {
                id: this.worksheet.vehicleId,
                vehicleLicensePlate: this.worksheet.vehiclePlateNumber,
              };
            }

            this.loadProductInfo();

            this.loadSignatures();

            this.loadInventoryDetails();

            this.generateImages();

            this.loaded = true;
          })
          .catch(() => {
            this.addErrorMessage("Error loading worksheet");
            this.loaded = true;
          });
      });
    },
    async setDependencies() {
      return null;
    },
    totalWeight(entries) {
      if (entries && entries.length > 0) {
        return entries.reduce((sum, a) => {
          return (sum += (a.netWeight + a.netAdjustmentWeight));
        }, 0);
      } else {
        return 0;
      }
    },
    async loadProductInfo() {
      if (this.worksheet.productId) {
        return await this.fetchCategory(this.worksheet.productId).then(
          (resp) => {
            this.productInfo = resp;

            return this.productInfo;
          }
        );
      } else {
        return null;
      }
    },
    async loadSignatures() {
      if (this.worksheet.requireSignature) {
        return await this.$axios(
          "/worksheetSignature/by-worksheet/" + this.worksheet.id
        ).then((resp) => {
          this.signatures = resp.data;
          return this.signatures;
        });
      } else {
        return null;
      }
    },
    entryIndex(entry) {
      if (this.worksheet && this.worksheet.entries) {
        const index = this.worksheet.entries.findIndex((e) => e.id == entry.id);
        if (index >= 0) {
          return index + 1;
        } else {
          return 0;
        }
      }
    },
    loadInventoryDetails() {
      if (this.worksheet.entries) {
        const inventoryIds = this.worksheet.entries
          .filter((e) => e.inventoryId)
          .map((e) => e.inventoryId);
        if (inventoryIds && inventoryIds.length > 0) {
          const params = {
            ids: inventoryIds ? inventoryIds.join(",") : null,
            liteMode: false,
          };

          this.$axios.get("/inventory/by-ids", { params }).then((resp) => {
            this.inventories = resp.data;
            if (this.inventories && this.inventories.length > 0) {
              this.inventories.forEach((i) => {
                if (i.fields && i.fields.length > 0) {
                  const docNumberField = i.fields.find((f) =>
                    f.field.toLowerCase().includes("document no")
                  );
                  i.documentNumber = docNumberField
                    ? docNumberField.fieldValueString
                    : "";
                }
              });
            }
          });
        }
      }
    },
    getEntryLotNumber(inventoryId, unitId) {
      if (this.inventories && this.inventories.length > 0) {
        const inventory = this.inventories.find((i) => i.id == inventoryId);
        if (
          inventory &&
          inventory.unitDetails &&
          inventory.unitDetails.length > 0
        ) {
          const inventoryUnit = inventory.unitDetails.find(
            (d) => d.id == unitId
          );
          return inventoryUnit.code;
        } else {
          return inventory ? inventory.documentNumber:'';
        }
      } else {
        return null;
      }
    },
    updateWorksheet(worksheet) {
      const vehicleId = this.vehicle ? this.vehicle.id : null;
      if (
        this.customerMemo != worksheet.customerMemo ||
        this.weightSlipNo != worksheet.documentNumber ||
        this.certificateNumber != worksheet.certificateNumber ||
        this.poNumber != worksheet.poNumber ||
        vehicleId != worksheet.vehicleId
      ) {
        worksheet.customerMemo = this.customerMemo;
        worksheet.documentNumber = this.weightSlipNo;
        worksheet.certificateNumber = this.certificateNumber;
        worksheet.poNumber = this.poNumber;
        worksheet.vehicleId = vehicleId;
        worksheet.vehiclePlateNumber = this.vehicle.vehicleLicensePlate;

        const payload = Object.assign({}, worksheet);
        delete payload.files;
        this.updateProcessWorksheet(payload);
      }
    },
    generateImages() {
      if (this.worksheet.files && this.worksheet.files.length > 0) {
        this.images = this.worksheet.files.map((file) => {
          const src = `${process.env.VUE_APP_BASE_URL}/file/worksheet/${file.id}`;
          return { src, id: file.id, selected: true };
        });
      } else {
        this.images = [];
      }
    },
  },
  async created() {
    this.id = this.$route.params.id;

    this.setInstance();

    const parseResult = parseDomain(window.location.hostname);
    const { domain, topLevelDomains } = parseResult;

    if (domain) {
      const topLevel = topLevelDomains.join(".");
      this.domainUrl = `https://factory.${domain}.${topLevel}/#`;
    } else {
      this.domainUrl = `http://localhost:${process.env.VUE_APP_PORT}/#`;
    }
  },
};
</script>
<style scoped>
table.qr-info > tbody > tr > td:nth-child(1) {
  padding-right: 5px;
}

div.break-before {
  page-break-before: always;
}

div.dont-break {
  break-inside: avoid;
}

.cell-spacing {
  border-collapse: separate;
  border-spacing: 10px;
}

div.footer-spacing {
  height: 50px;
}

@media print {
  @page {
    size: A4;
    margin: 10mm 10mm 10mm 10mm;
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }

  .mb-10 {
    margin-bottom: 5px !important;
  }

  .print-img {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }

  div.print-flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    justify-content: space-between !important;
  }
}
</style>
